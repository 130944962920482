import Helmet from "react-helmet";

const metaFallback = {
  title: "Den demokratiska mötesplatsen för alla | Almedalsveckan Play",
  description: "Den demokratiska mötesplatsen för alla",
  siteUrl: "https://almedalsveckanplay.info/",
  twitterCreator: "@Almedalsvecka",
  type: "website",
};

const Seo = ({ image, title, description, pathSlug, keywords }) => {
  const metaDescription = description || metaFallback.description;

  const url = pathSlug
    ? `${metaFallback.siteUrl}${pathSlug}`
    : `${metaFallback.siteUrl}`;

  const metaImage =
    image || "https://static.univid.io/logo/almedalen-logo-white-1.jpg";
  // https://static.univid.io/almedalen/background/pre-bg.jpg

  const finalTitle =
    title != null ? title + " | Almedalsveckan Play" : metaFallback.title;

  return (
    <div>
      <Helmet titleTemplate={`${finalTitle}`}>
        <html lang="sv" />
        <title>{finalTitle}</title>

        <link
          rel="icon"
          sizes="16x16"
          type="image/png"
          href={process.env.PUBLIC_URL + "/favicon-16x16.png"}
        />
        <link
          rel="icon"
          sizes="32x32"
          type="image/png"
          href={process.env.PUBLIC_URL + "/favicon-32x32.png"}
        />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta charSet="utf-8" />
        <link rel="canonical" key={url} href={url} />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content={keywords ? keywords.join() : metaDescription}
        />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={finalTitle} />
        <meta property="og:type" content={metaFallback.type} />
        <meta name="og:description" content={metaDescription} />

        {metaImage && <meta property="og:image" content={metaImage} />}

        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:title" content={finalTitle} />
        <meta name="twitter:creator" content={metaFallback.twitterCreator} />
        <meta name="twitter:card" content="summary_large_image" />
        {metaImage && <meta name="twitter:image" content={metaImage} />}
      </Helmet>
    </div>
  );
};

export default Seo;
