import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

import styles from "./CookieBanner.module.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const cookieName = "acceptedAnalyticsCookies";
const tabIndex = "32767";

const CookieBanner = ({ forceOpen, setForceOpenCookieBanner }) => {
  const userHasMadeAChoice = useRef(cookies.get(cookieName) != null);
  const userHasAcceptedCookies = useRef(cookies.get(cookieName) === "true");
  const [showCookieMessage, setShowCookieMessage] = useState(false);

  const location = useLocation();
  const { trackPageView, enableLinkTracking } = useMatomo();

  if (userHasAcceptedCookies) {
    enableLinkTracking();
  }

  useEffect(() => {
    console.log("New page view");
    // TODO: Should this be here or not? The documentation doesn't show it for the npm package (when using enableLinkTracking).

    if (userHasAcceptedCookies.current) {
      trackPageView();
    }
  }, [location]);

  useEffect(() => {
    if (!userHasMadeAChoice.current || forceOpen) {
      setShowCookieMessage(true);
    }
  }, [forceOpen]);

  const enableCookie = () => {
    cookies.set(cookieName, "true", {
      path: "/",
      maxAge: 2592000,
    });

    trackPageView();

    setForceOpenCookieBanner(false);
    setShowCookieMessage(false);
  };

  const disableCookie = () => {
    cookies.set(cookieName, "false", {
      path: "/",
      maxAge: 2592000,
    });

    setForceOpenCookieBanner(false);
    setShowCookieMessage(false);
  };

  if (!showCookieMessage) {
    return null;
  }

  return (
    <div className={styles.CookieBarContainer} tabIndex={tabIndex}>
      <span>
        Almedalsveckan Play använder analys-kakor (cookies) för att förbättra
        tjänsten.
      </span>
      <div className={styles.CookieBarButtonContainer}>
        <div
          tabIndex={tabIndex}
          className={styles.CookieBarButton}
          onClick={() => {
            enableCookie();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              enableCookie();
            }
          }}
        >
          Acceptera
        </div>
        <div
          tabIndex={tabIndex}
          className={styles.CookieBarButton}
          style={{ backgroundColor: "rgb(200 200 200)" }}
          onClick={() => {
            disableCookie();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              disableCookie();
            }
          }}
        >
          Neka
        </div>
        <Link
          className={styles.CookieBarMoreInfo}
          to={"/cookies"}
          tabIndex={tabIndex}
        >
          Mer info
        </Link>
      </div>
    </div>
  );
};

export default connect()(CookieBanner);
