import { THEME_COLORS } from "../components/cssvariables/colors";

const initialState = {
  themeName: "unividDefaultGray",
  primaryColor: null,
  secondaryColor: null,
  accentColor: null,
  headerFade: null,
  previewing: false,
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_THEME": {
      let toReturn = { ...state };

      // action.themeName == "custom"
      if (action.themeName == null || !(action.themeName in THEME_COLORS)) {
        action.themeName = "unividDefaultGray";
      }

      if (action.previewing != null) {
        toReturn.previewing = action.previewing;
      }

      toReturn.themeName = action.themeName;

      if (state.previewing == true && action.previewing == null) {
        // Do not set the colors
      } else {
        toReturn = {
          ...toReturn,
          primaryColor: action.primaryColor,
          secondaryColor: action.secondaryColor,
          accentColor: action.accentColor,
          headerFade: action.headerFade,
        };
      }

      return toReturn;
    }
    case "RESET_THEME":
      return initialState;
    default:
      return state;
  }
}
