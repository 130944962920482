import { BrowserRouter as Router } from "react-router-dom";

import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";

import { createRoot } from "react-dom/client";

import { browserName } from "react-device-detect";

import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

import "@fontsource/roboto";

import App from "./App.js";

import rootReducer from "./redux/reducer";

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(thunk)));

const matomoEnabled =
  process.env.NODE_ENV === "production" &&
  !process.env.REACT_APP_IS_STAGING &&
  browserName != "Chrome Headless";

const instance = createInstance({
  urlBase: "https://gotland.matomo.cloud/",
  siteId: 7,
  disabled: !matomoEnabled, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // Is false, as we do this through enableLinkTracking in App.js (https://www.npmjs.com/package/@datapunt/matomo-tracker-react)
  configurations: {},
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <MatomoProvider value={instance}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </MatomoProvider>
);
