export const COLORS = {
  primary: "rgb(73, 111, 140)",
  primaryStrong: "rgba(73, 111, 140, 0.8)",
  primarySoft: "rgba(73, 111, 140, 0.5)",
  primaryHint: "rgba(73, 111, 140, 0.1)",

  secondary: "rgb(138, 195, 240)",
  secondaryStrong: "rgba(138, 195, 240, 0.8)",
  secondarySoft: "rgba(138, 195, 240, 0.5)",
  secondaryHint: "rgba(138, 195, 240, 0.1)",

  accent: "rgb(122, 58, 113)",
  accentStrong: "rgba(122, 58, 113, 0.8)",
  accentSoft: "rgb(122, 58, 113, 0.5)",
  accentHint: "rgb(122, 58, 113, 0.1)",

  nicepink: "pink",
};

export const THEME_COLORS = {
  unividDefaultGray: {
    primary: "#efefef",
    secondary: "#505050",
    accentColor: "#efefef",
    altColor: "#505050",
  },

  unividBasicBlue: {
    primary: COLORS.primary,
    secondary: "#3173a6",
    accentColor: COLORS.primary,
    altColor: "#3173a6",
  },
  blueAndChildish: {
    primary: "#5FBFF9",
    secondary: "#095079",
    accentColor: "#5FBFF9",
    altColor: "#095079",
  },

  creativeAndColorful: {
    primary: "#065471",
    secondary: "#0A91AB",
    accentColor: "#FFC045",
    altColor: "#898B8A",
  },

  simplyCorporate: {
    primary: "#254E58",
    secondary: "#0099b5",
    accentColor: "#254E58",
    altColor: "#0099b5",
  },

  sophisticated: {
    primary: "#557A95",
    secondary: "#B1A296",
    accentColor: "#7395AE",
    altColor: "#379683",
  },

  earthy: {
    primary: "#659DBD",
    secondary: "#FBEEC1",
    accentColor: "#BC986A",
    altColor: "#DAAD86",
  },

  purpleAndPink: {
    primary: "#dcb4cc",
    secondary: "#a85a89",
    accentColor: "#dcb4cc",
    altColor: "#a85a89",
  },

  futuristic: {
    primary: "#2C3531",
    secondary: "#116466",
    accentColor: "#D9B08C",
    altColor: "#D1E8E2",
  },

  blueAndDark: {
    primary: "#1D2D50",
    secondary: "#133B5C",
    accentColor: "#1E5F74",
    altColor: "#808080",
  },
  grayAndWhite: {
    primary: "#fff",
    secondary: "#505050",
    accentColor: "#fff",
    altColor: "#505050",
  },
};
