import { useState, useEffect, Suspense, lazy } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { connect } from "react-redux";

import styles from "./components/css/App.module.css";

import "./components/css/Text.css";

import "./components/css/App.css";

import Seo from "./components/seo/Seo";
import CookieBanner from "./components/cookies/CookieBanner";

const isMobile = require("is-mobile");

const InfoPageWrapper = lazy(() =>
  import("./components/infopages/InfoPageWrapper")
);

const RoomFeed = lazy(() => import("./components/RoomFeed"));
const CourseRoom = lazy(() => import("./components/CourseRoom"));

const PageNotFoundAlmedalen = lazy(() =>
  import("./components/PageNotFoundAlmedalen")
);

function detectIOSOrSafari() {
  if (
    navigator == null ||
    navigator.userAgent == null ||
    navigator.platform == null
  ) {
    return false;
  }

  if (
    (navigator.userAgent.indexOf("Chrome") <= 0 &&
      navigator.userAgent.indexOf("Safari") >= 0 &&
      "ontouchend" in document) ||
    (navigator.userAgent.indexOf("Mac") >= 0 && "ontouchend" in document)
  ) {
    return true;
  }

  switch (navigator.platform) {
    case "iPad Simulator":
    case "iPhone Simulator":
    case "iPod Simulator":
    case "iPad":
    case "iPhone":
    case "iPod":
      return true;
  }

  return false;
}

const isApple = detectIOSOrSafari();
const isMobileUnit = isMobile();

const App = () => {
  const [forceOpenCookieBanner, setForceOpenCookieBanner] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", appSize.bind(this));

    window
      .matchMedia("(orientation: portrait)")
      .addListener(appSize.bind(this));

    window.addEventListener("orientationchange", appSize.bind(this));

    appSize();
  }, []);

  const getDimensions = (methodPriorityArray) => {
    let widthSuggestion = null;
    let heightSuggestion = null;

    // method === undefined when length of methodPriorityArray is 0
    const method = methodPriorityArray.pop();

    switch (method) {
      case "A":
        if ("getBoundingClientRect" in document.body) {
          const bodyRect = document.body.getBoundingClientRect();
          heightSuggestion = bodyRect.bottom - bodyRect.top;
          widthSuggestion = bodyRect.right - bodyRect.left;
        }

        break;
      case "B":
        if (
          document.documentElement &&
          "clientHeight" in document.documentElement &&
          "clientWidth" in document.documentElement
        ) {
          widthSuggestion = document.documentElement.clientWidth;
          heightSuggestion = document.documentElement.clientHeight;
        }
        break;
      case "C":
        widthSuggestion = window.innerWidth;
        heightSuggestion = window.innerHeight;
        break;
      default:
        console.log("Default method, return null");
        return null;
    }

    if (
      widthSuggestion != null &&
      widthSuggestion != 0 &&
      heightSuggestion != null &&
      heightSuggestion != 0
    ) {
      return {
        width: widthSuggestion,
        height: heightSuggestion,
      };
    } else {
      return getDimensions(methodPriorityArray);
    }
  };

  const appSize = () => {
    // 1) BODY DETERMINS THE HEIGHT
    // 2) ROOT DISPLAYS IT

    let newWidthString = "1vw";
    let newHeightString = "1vh";

    if (isMobileUnit) {
      let dimensionsObject = null;

      if (isApple) {
        // Priority order A -> B -> C (reversed order in method call as .pop() is used)
        dimensionsObject = getDimensions(["C", "B", "A"]);
      } else {
        // Priority order B -> C (reversed order in method call as .pop() is used)
        dimensionsObject = getDimensions(["C", "B"]);
      }

      if (dimensionsObject != null) {
        const newWidth = dimensionsObject.width;
        const newHeight = dimensionsObject.height;

        if (newWidth != null && newHeight != null) {
          newWidthString = newWidth * 0.01 + "px";
          newHeightString = newHeight * 0.01 + "px";
        }
      }
    }

    if (document.getElementById("root") != null) {
      document.getElementById("root").style.setProperty("--vw", newWidthString);
      document
        .getElementById("root")
        .style.setProperty("--vh", newHeightString);
    } else {
      document.documentElement.style.setProperty("--vw", newWidthString);
      document.documentElement.style.setProperty("--vh", newHeightString);
    }
  };

  return (
    <div className={styles.AppContainer}>
      {/* <ScrollRestore /> */}
      <Seo />
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route exact path="/" element={<RoomFeed />} />
          <Route exact path="/infopage" element={<InfoPageWrapper />} />

          <Route
            exact
            path="/om"
            element={<InfoPageWrapper currentPage={"om"} />}
          />
          <Route
            exact
            path="/tillganglighet"
            element={<InfoPageWrapper currentPage={"tillganglighet"} />}
          />
          <Route
            exact
            path="/personuppgifter"
            element={<InfoPageWrapper currentPage={"personuppgifter"} />}
          />
          <Route
            exact
            path="/cookies"
            element={
              <InfoPageWrapper
                currentPage={"cookies"}
                setForceOpenCookieBanner={setForceOpenCookieBanner}
              />
            }
          />
          <Route
            exact
            path="/traffpunkter"
            element={<InfoPageWrapper currentPage={"traffpunkter"} />}
          />
          <Route
            exact
            path="/safungerarplay"
            element={<InfoPageWrapper currentPage={"safungerarplay"} />}
          />
          <Route
            exact
            path="/kontakt"
            element={<InfoPageWrapper currentPage={"kontakt"} />}
          />
          <Route
            exact
            path="/program"
            element={<InfoPageWrapper currentPage={"program"} />}
          />
          <Route
            exact
            path="/sidankundeintehittas"
            element={<PageNotFoundAlmedalen />}
          />

          <Route exact path="/:id" element={<CourseRoom />} />

          {/* The default */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
      <CookieBanner
        forceOpen={forceOpenCookieBanner}
        setForceOpenCookieBanner={setForceOpenCookieBanner}
      />
    </div>
  );
};

export default connect()(App);
